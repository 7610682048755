<div>
  <mat-dialog-content class="LD">
    <!-- <mat-spinner  [diameter]="50"></mat-spinner> -->
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h2 style="color: #fff">{{ data.message || 'Loading...' }}</h2>
  </mat-dialog-content>
</div>
