import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ToolsService } from './tools.service';
import { getAuth, signOut } from 'firebase/auth';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Socket, io } from 'socket.io-client';
import { ChatMsg, Company, Organization, OrganizationsAndCompanies } from '../types';
import { Roles, Paths, ROWS_PER_PAGE } from '../const/const';
import { flattenOrganizations, getSortedOrganizationsAndMap } from '../utils/organizations.utils';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';

@Injectable()
export class MainService {
  constructor(
    public router: Router,
    public http: HttpService,
    public tools: ToolsService,
    public route: ActivatedRoute,
    private httpClient: HttpClient
  ) {
    const url = this.NS_URL.substring(0, this.NS_URL.lastIndexOf('/'));
    this.socket = this.createSocketConnection(url, this.userData.email, this.userData.firebaseUserID);
    this.checkRoute();
  }

  currentURL: string;
  activeSort: string = 'Last interaction';
  tab: number = JSON.parse(localStorage.getItem('c-tab')) || 2;
  companies: Company[] = [];
  companiesSorted: Company[] = [];
  verificationCompanies: Company[] = [];
  companyIDs: string[] = [];
  organizationsIDs: string[] = [];
  organizations: any = [];
  dropdownOrganizationsAndCompanies: OrganizationsAndCompanies[] = [];
  appbarOrganizationsAndCompanies: OrganizationsAndCompanies[] = [];
  organizationsMap = new Map();
  appbarOrganizationsMap = new Map();
  companiesSelectionMap = new Map<string, number>();
  socket: any;
  NS_URL = environment.NS_URL;

  userID: string = localStorage.getItem('c-userID') || '';
  userData: any = JSON.parse(localStorage.getItem('c-userData')) || {};
  consoleType: any = localStorage.getItem('consoleType') || '';

  userCompanyIDs: string[] = this.userData.companies || [];
  conversations: any = [];
  conversationsSorted: any = [];

  companiesUnderOrganizations = {};
  userCompaniesUnderOrganizations = {};
  handOffReasons: any = [];
  newMessagesCount = 0;
  unverifiedMsgsCount = 0;
  isVerificationPage: boolean;
  verificationMsg: ChatMsg = {};
  doneChat: boolean;
  selectAll: boolean;
  rowsPerPage: number = localStorage.getItem('rowsPerPage')
    ? JSON.parse(localStorage.getItem('rowsPerPage'))
    : ROWS_PER_PAGE;

  private createSocketConnection(NS_URL: string, userEmail: string, firebaseUserID: string): Socket {
    const socket = io(NS_URL, {
      transports: ['websocket'],
      query: {
        email: userEmail,
        firebaseUserID,
      },
    });

    // Handle the 'connect' event to set the client ID when connected.
    socket.on('connect', () => {});

    // Handle the 'disconnect' event to attempt reconnection.
    socket.on('disconnect', () => {});

    // Handle the 'reconnect' event to indicate successful reconnection.
    socket.on('reconnect', () => {});

    return socket;
  }
  selectTab(value) {
    localStorage.setItem('c-tab', value);
    this.tab = value;
  }

  private cleanConversation: () => void; // for clean few arrays and variables of conversations components
  cleanConversationComp(fn: () => void) {
    this.cleanConversation = fn;
    // from now on, call myFunc wherever you want inside this service
  }

  private createOrgSelectionMap(): void {
    this.companies.forEach((company) => {
      const organizationID = company.organizationID;
      this.companiesSelectionMap.set(organizationID, 0);
    });

    this.updateSelectionMap();
  }

  private updateSelectionMap(): void {
    this.userCompanyIDs.forEach((companyID) => {
      const organizationID = this.companies.find((item: Company) => item.companyID === companyID)?.organizationID;
      if (organizationID) {
        const currentCount = this.companiesSelectionMap.get(organizationID) || 0;
        this.companiesSelectionMap.set(organizationID, currentCount + 1);
      }
    });
  }

  doLogout() {
    signOut(getAuth()).then(() => {
      localStorage.clear();
      this.router.navigate(['/account']).then(() => {
        window.location.reload();
      });
    });
  }

  public loadCompanies(): Observable<typeof EMPTY> {
    return this.httpClient.get<Company[]>(this.tools.NS_URL + '/companies').pipe(
      tap((res) => {
        this.handleOrganizationsAndCompanies(res); // assigning companies and organizations
      }),
      catchError((err) => {
        this.tools.loading(false);
        return EMPTY;
      }),
      map(() => EMPTY)
    );
  }

  async refreshConversations() {
    this.tools.loading(true, 'Processing...');
    await this.loadConversations();
  }

  loadConversations() {
    return new Promise((resolve: any) => {
      this.cleanConversation();
      let obj: any = {};
      if (this.userCompanyIDs.length) {
        obj.companyIDs = this.userCompanyIDs;
      }
      if (!this.userCompanyIDs.length) {
        this.conversations = [];
        this.conversationsSorted = [];
        this.tools.loading(false);
        return;
      }
      if (this.isVerificationPage) {
        obj.chat = true;
      }
      this.http.$httpPost(this.tools.NS_URL + '/verification/tenants', obj).subscribe(
        async (res: any) => {
          res = this.sortConversationByAPI(res);
          this.conversations = res;
          this.newMessagesCount = 0;
          this.unverifiedMsgsCount = 0;
          this.conversations.forEach((i) => {
            i.user_id = this.userID;
            i.user_email = this.userData.email;
            i.companyID = i.companyID;
            this.companies.forEach((company) => {
              if (company.companyID === i.companyID) {
                i.organizationID = company.organizationID;
                i.organization_name = company.organization_name;
              }
            });

            if (!i.person.phone) {
              i.person.phone = '';
            }
            if (!i.person.email || i.person.email == ' ') {
              i.person.email = '';
            }
            if (i.svUnseen) {
              this.newMessagesCount = this.newMessagesCount + 1;
            }
            if (i.count) {
              this.unverifiedMsgsCount = this.unverifiedMsgsCount + 1;
            }
          });
          this.conversationsSorted = this.conversations.slice();

          this.tools.loading(false);
          resolve();
        },
        (err) => {
          this.tools.loading(false);
          resolve();
        }
      );
    });
  }

  sortConversationByAPI(array) {
    if (this.activeSort == 'Status') {
      array = array.sort((a, b) => b.svUnseen - a.svUnseen);
    } else if (this.activeSort == 'Last interaction') {
      array = array.sort((a, b) => new Date(b.last_interaction).getTime() - new Date(a.last_interaction).getTime());
    } else if (this.activeSort === 'SupervisorHandleStatusLevel') {
      this.sortByHandleStatusLevel(array);
    }
    return array;
  }

  sortByHandleStatusLevel(conversations): void {
    conversations.sort((a, b) => {
      const statusOrder = { Escalated: 0, OnHold: 1, Regular: 2 };
      return statusOrder[a.supervisorHandleStatusLevel.status] - statusOrder[b.supervisorHandleStatusLevel.status];
    });
  }

  assignRelatedCompanies() {
    this.userCompanyIDs = this.isVerificationPage ? this.userData.verificationCompanies : this.userData.companies;
  }

  public handleOrganizationsAndCompanies(companies: Company[]): void {
    this.assignRelatedCompanies();
    this.companiesUnderOrganizations = {};
    this.userCompaniesUnderOrganizations = {};
    this.organizationsIDs = [];
    this.organizations = [];
    this.companyIDs = [];
    this.companies = companies;
    this.companiesSorted = this.tools.dynamicSort(this.companies.slice(), 'company_name');
    this.verificationCompanies = this.tools.dynamicSort(this.companies.slice(), 'company_name');
    for (const company of this.companies) {
      this.companyIDs.push(company.companyID);
      if (!this.organizationsIDs.includes(company.organizationID)) {
        this.organizationsIDs.push(company.organizationID);
        this.organizations.push({ name: company.organization_name, organizationID: company.organizationID });
      }
    }
    this.prepareSupervisorDropdownOrganizations();
    this.prepareAppbarDropdownOrganizations();
    this.createOrgSelectionMap();
  }

  private createOrganizationsMap(obj: { [organizationID: string]: Organization }, mapPropertyName: string): void {
    this[mapPropertyName] = new Map();
    Object.keys(obj).forEach((key) => {
      this[mapPropertyName].set(key, obj[key]);
    });
  }

  private prepareAppbarDropdownOrganizations(): void {
    const userCompanyIDsSet = new Set(this.userCompanyIDs);
    const userCompanies = this.companies.filter((company) => userCompanyIDsSet.has(company.companyID));
    const sortedData = getSortedOrganizationsAndMap(userCompanies);
    let dropdownOrganizations = sortedData.sortedOrganizations;
    this.createOrganizationsMap(sortedData.organizationMap, 'appbarOrganizationsMap');
    dropdownOrganizations = this.tools.dynamicSort(dropdownOrganizations, 'name');
    this.appbarOrganizationsAndCompanies = flattenOrganizations(dropdownOrganizations);
  }

  private prepareSupervisorDropdownOrganizations(): void {
    const sortedData = getSortedOrganizationsAndMap(this.companies);
    let dropdownOrganizations = sortedData.sortedOrganizations;
    this.createOrganizationsMap(sortedData.organizationMap, 'organizationsMap');
    dropdownOrganizations = this.tools.dynamicSort(dropdownOrganizations, 'name');
    this.dropdownOrganizationsAndCompanies = flattenOrganizations(dropdownOrganizations);
  }

  loadHandingOffReasons() {
    return new Promise((resolve: any, reject: any) => {
      this.http.$httpGet(this.tools.NS_URL + '/handoffReasonPickList').subscribe(
        async (res: any) => {
          this.handOffReasons = res;
          resolve();
        },
        (err) => {
          reject();
        }
      );
    });
  }

  public changeConsole(role: Roles): void {
    localStorage.setItem('consoleType', role);
    const url = role === Roles.ADMIN ? Paths.ADMIN : Paths.SUPERVISOR;

    if (role === Roles.ADMIN) {
      this.selectTab(2); // set Insights as initial tab for Admins
    }

    this.router.navigate([url]);
    this.tools.reloadApp();
  }

  updateChat(object: object, text?: string) {
    return new Promise((resolve: any, reject: any) => {
      this.http.$httpPost(this.tools.NS_URL + '/chat', object, { responseType: 'text' }).subscribe(
        async (res: any) => {
          if (text) {
            this.tools.toast(text);
          }
          resolve();
        },
        (err) => {
          reject(err);
          console.log(err);
        }
      );
    });
  }

  updateTenant(object: object, text?: string) {
    return new Promise((resolve: any, reject: any) => {
      this.http.$httpPost(this.tools.NS_URL + '/tenant', object, { responseType: 'text' }).subscribe(
        async (res: any) => {
          if (text) {
            this.tools.toast(text);
          }
          resolve();
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  updateRowsPerPage(rowsPerPage: number) {
    this.http.$httpPost(this.tools.NS_URL + '/rows-per-page', { rowsPerPage }, { responseType: 'text' }).subscribe(
      async (res: any) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  checkRoute() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = location.pathname;

        this.isVerificationPage = event.url === '/main/verification';
        if (event.url === '/main/admin-verification') {
          this.isVerificationPage = true;
        }
      }
    });
  }
}
